import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './pages/App';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
import './styles/index.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
    
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);
