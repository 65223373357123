import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        {children}
      </main>
      
      <footer className="p-4 md:p-8 lg:p-10 text-gray-800">
        <div className="mx-auto max-w-screen-xl text-center">
          <ul className="flex flex-wrap justify-center items-center mb-6">
            <li>
              <a href="https://www.fmf.nl/vereniging" className="mr-4 hover:underline md:mr-6">
                About FMF
              </a>
            </li>
            <li>
              <a href="https://professorfrancken.nl/association" className="mr-4 hover:underline md:mr-6">
                About T.F.V. 'Professor Francken'
              </a>
            </li>
            {/* <li>
              <Link to="/faq" className="mr-4 hover:underline md:mr-6">
                FAQs
              </Link>
            </li> */}
            <li>
              <Link to="/contact" className="mr-4 hover:underline md:mr-6">
                Contact
              </Link>
            </li>
          </ul>

          <span className="text-sm sm:text-center">
            © 2024 Fysisch-Mathematische Faculteitsvereniging & T.F.V. 'Professor Francken'. All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
