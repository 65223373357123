import React from 'react';
import Layout from '../components/layout';
import Breadcrumb from '../components/breadcrumb';
// import { ReactComponent as FmfIcon } from '../img/fmf.svg';
import CoolHiddenContainer from '../components/CoolHiddenContainer';

const FAQPage: React.FC = () => {
  return (
    <Layout>
      <CoolHiddenContainer />
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div className="max-w-screen-md mb-8 lg:mb-18">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">Frequently Asked Questions (FAQ)</h2> 

        <Breadcrumb />

        <p className="text-gray-500 sm:text-xl dark:text-gray-400">
        Here are some common questions and answers:
        </p>
      </div>
      
    </div>
    </Layout>
    
  );
};

export default FAQPage;
