import React, { useState } from 'react';
import FMFLogo from '../img/FMF_square_blue.png';
import PFLogo from '../img/professor_Francken_2.png';
import UsedBooks from '../img/recycle.png';
import NewBooks from  '../img/shop.png';
import Layout from '../components/layout';


interface DropdownContentProps {
  content: React.ReactNode;
  position?: string;
}

const DropdownContent: React.FC<DropdownContentProps> = ({ content, position }) => (
  <div
    className={`dropdown-content overflow-visible w-60 px-5 py-3 text-sm bg-white rounded-lg shadow border z-50 absolute ${position !== '' ? position : ""}`}
  >
    {content}
  </div>
);


type DropdownState = {
  FMF: boolean;
  New: boolean;
  Used: boolean;
  PF: boolean;
};



function App() {
  const [dropdownState, setDropdownState] = useState<DropdownState>({
    FMF: false,
    New: false,
    Used: false,
    PF: false,
  });

  const handleDropdownToggle = (dropdownName: keyof DropdownState) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  

  return (
    <Layout>
    <div className="App">
      
      
      <div className="flex flex-col items-center">
        <h1 className="text-5xl font-bold mb-8 mt-10">New or Used books??</h1>
        <p className="text-black text-center">Welcome to our book portal! This website is dedicated exclusively to connect the two website for new and used book sale.</p>
        <p className="mb-6 text-black text-center">FMF is responsible for the sale of new books, while Professor Francken handles the selection of used books.</p>
        
        <div className="gallery relative">
          <div className="gallery-item">
            <button
              className='glowing-btn'
              id="dropdownDelayButtonFMF"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="50"
              data-dropdown-trigger="hover"
              type="button"
              onMouseEnter={() => handleDropdownToggle('FMF')}
              onMouseLeave={() => handleDropdownToggle('FMF')}
            >
              <a href="https://fmf.nl/studie/boekenbestelling">
                <img src={FMFLogo} className="text-white" alt="FMF"/>
              </a>
            </button>
            {dropdownState.FMF && <DropdownContent content={<p>The Fysisch-Mathematische Faculteitsvereniging is the association for all the degrees programs in (Applied) Mathematics and Physics.</p>}/>}
          </div>
          <div className="gallery-item overflow-visible">
            <button
              id="dropdownDelayButtonNew"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="50"
              data-dropdown-trigger="hover"
              type="button"
              onMouseEnter={() => handleDropdownToggle('New')}
              onMouseLeave={() => handleDropdownToggle('New')}
            >
              <a href="https://fmf.nl/studie/boekenbestelling">
                <img src={NewBooks} alt="The mountain"/>
              </a>
            </button>
            {/* {dropdownState.New && <DropdownContent content={<p>We have partnered with WO4YOU to provide our members with textbooks for your courses at a 15% discount. You can also have your books delivered to the FMF room.</p>}/>} */}
          </div>
          <div className="gallery-item">
            <button
              id="dropdownDelayButtonUsed"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="50"
              data-dropdown-trigger="hover"
              type="button"
              onMouseEnter={() => handleDropdownToggle('Used')}
              onMouseLeave={() => handleDropdownToggle('Used')}
            >
              <a href="https://professorfrancken.nl/study/books">
                <img src={UsedBooks} alt="a river"/>
              </a>
              {/* {dropdownState.Used && <DropdownContent content={<p>On this page you will find all second hand books that are for sale at Francken. If you’re intersted in buying a book you can just come to the boardroom and pick up your books. </p>} />} */}
            </button>
            
          </div>
          <div className="gallery-item">
            <button
              className='glowing-btn'
              id="dropdownDelayButtonPF"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="50"
              data-dropdown-trigger="hover"
              type="button"
              onMouseEnter={() => handleDropdownToggle('PF')}
              onMouseLeave={() => handleDropdownToggle('PF')}
            >
              <a href="https://professorfrancken.nl/study/books">
                <img src={PFLogo} className="text-white" alt="professor Francken"/>
              </a>
            </button>
            {dropdownState.PF && <DropdownContent content={<p>The Technisch Fysische Vereniging ‘Professor Francken’  is the study association for engineering physics.</p>} />}
          </div>
        </div>
      </div>
      
    </div>
    </Layout>
  );
}

export default App;
