import React from 'react';
import Layout from '../components/layout';
import Breadcrumb from '../components/breadcrumb';
import { ReactComponent as FmfIcon } from '../img/fmf.svg';
import PFIcon from '../img/professor_Francken.png';

const ContactPage: React.FC = () => {
  return (
    <Layout>
      
    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 text-black">
      <div className="max-w-screen-md mb-8 lg:mb-18">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">Contact Us</h2>

        <Breadcrumb />

        <p className="text-black sm:text-xl dark:text-gray-400">
          Do you need help with your order? Is it unclear how the process works? Or do you just want to follow us on all our social media? Then look below!
        </p>
      </div>
      <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">
        <div>
          <a href="mailto: bytemath@lorenzozambelli.it?Subject=Hello" target="_top" title="Email the ByteMath team">
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-white lg:h-12 lg:w-12 bg-primary-900">
              <svg
                className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-white">Email</h3>
          </a>
          <p className="text-gray-800">
            We are always open to reply to your inquiries and most importantly, feedbacks on how to improve this service. You can contact the FMF at <a href="mailto:secretary@fmf.nl?Subject=Book Sale Inquiry" target="_top" title="Email the secretary of FMF" className="text-gray-400 font-medium hover:text-white">secretary@fmf.nl</a> and Professor Francken at <a href="mailto:books@professorfrancken.nl?Subject=Book Sale Inquiry" target="_top" title="Email Professor Francken" className="text-gray-400 font-medium hover:text-white ">books@professorfrancken.nl</a>.
          </p>
        </div>
        <div>
          <a href="https://instagram.com/bytemath_" target="_blank" title="Contact the ByteMath team at Instagram" rel="noopener noreferrer">
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-white lg:h-12 lg:w-12 bg-primary-900">
              <svg
                className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-white">Instagram</h3>
          </a>
          <p className="text-gray-800">
            On our Instagram page, you can not only chat with us, but you can learn about us and our upcoming activities
          </p>
          <div className="flex p-1">
        <a href="https://www.instagram.com/fmf_groningen/" title="FMF instagram">
        <div className="flex justify-center items-center mb-4  rounded-full  bg-primary-900 p-2">
          <FmfIcon className="w-10 h-10 lg:w-20 lg:h-20 text-white" />
        </div>
      </a>
      <a href="https://www.instagram.com/tfvprofessorfrancken/" target="_top" title="tfvprofessorfrancken instagram page">
        <div className="flex justify-center items-center mb-4 rounded-full bg-white w-10 h-10 lg:w-20 lg:h-20 bg-primary-900 p-2">
          {/* <PFIcon className="w-10 h-10 lg:w-20 lg:h-20 text-white" /> */}
          
          <img src={PFIcon} className="text-white" alt="professor Francken"/>
        
        </div>
      </a>
    </div>
        </div>
        <div>
          <a href="https://www.youtube.com/channel/UCnyveEbLc5HYP2JNhjz6WtQ" target="_blank" title="Contact the ByteMath team at Youtube" rel="noopener noreferrer">
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-white lg:h-12 lg:w-12 bg-primary-900">
              <svg
                className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 576 512"
              >
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-white">Linkedin</h3>
          </a>
          <p className="text-gray-800">On our Linkedin pages, we post our activities with companies and academic-related.</p>
          <div className="flex align-middle items-center">
      <a href="https://www.linkedin.com/company/fysisch-mathematische-faculteitsvereniging/" title="FMF Linkedin page">
        <div className="flex justify-center items-center mb-4 rounded-full  bg-primary-900 p-2">
          <FmfIcon className="w-10 h-10 lg:w-20 lg:h-20 text-white" />
        </div>
      </a>
      <a href="https://www.linkedin.com/company/t.f.v.-professor-francken/?viewAsMember=true" target="_top" title="Linkedin page">
        <div className="flex justify-center items-center mb-4 rounded-full bg-white w-10 h-10 lg:w-20 lg:h-20 bg-primary-900 p-2">
          {/* <PFIcon className="w-10 h-10 lg:w-20 lg:h-20 text-white" /> */}
          
          <img src={PFIcon} className="text-white" alt="professor Francken"/>
        
        </div>
      </a>
      </div>
        </div>
        
      </div>
    </div>
    </Layout>
  );
};

export default ContactPage;
