import React, { useState, useEffect } from 'react';

interface ContainerProps {
  top: string;
  left: string;
  opacity?: number;
}

const CoolHiddenContainer: React.FC = () => {
  const [containers, setContainers] = useState<Array<ContainerProps>>([]);
  const [discoveredCount, setDiscoveredCount] = useState<number>(0);
  const [gameInProgress, setGameInProgress] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(128);
  const [level, setLevel] = useState<number>(1);

  useEffect(() => {
    if (discoveredCount === 0) {
      const initialContainers: ContainerProps[] = [{ top: '0', left: '0' }];
      setContainers(initialContainers);
    }
  }, [discoveredCount]);

  useEffect(() => {
    if (gameInProgress) {
      initializeGame();
    }
  }, [gameInProgress]);

  useEffect(() => {
    if (gameInProgress) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          addContainer();
          setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        } else {
          setGameInProgress(false);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [gameInProgress, timeLeft]);
  
  useEffect(() => {
    if (gameInProgress) {
      const moveTimer = setInterval(() => {
        setContainers((prevContainers) => {
          return prevContainers.map((container) => {
            if (container.opacity === 0) {
              return container;
            }
            const randomMoveX = Math.random() * 6.4 * level / 64;
            const randomMoveY = Math.random() * 640 * level / 64; // Faster upwards movement
            const newTop = parseFloat(container.top) - randomMoveY;
            const newOpacity = newTop < 0 ? 0 : container.opacity; // Set opacity to 0 if it reached the top
            return { ...container, top: `${newTop}vh`, opacity: newOpacity };
          });
        });
      }, 6464 * level / 6.4); // Slower movement

      return () => clearInterval(moveTimer);
    }
  }, [gameInProgress, level]);

  const addContainer = () => {
    const randomTop = '100'; // Start from the bottom of the screen
    const randomLeft = `${Math.random() * 80}vw`;
    setContainers((prevContainers) => [
      ...prevContainers,
      { top: randomTop, left: randomLeft, opacity: 1 },
    ]);
  };

  const initializeGame = () => {
    setGameInProgress(true);
    // initializeContainers();
  };

  const initializeContainers = () => {
    const initialContainers: ContainerProps[] = [];
    for (let i = 0; i < 64; i++) {
      const randomTop = `${Math.random() * 80}vh`;
      const randomLeft = `${Math.random() * 80}vw`;
      const opacity = level === 2 && i === 63 ? 0 : 1; 
      initialContainers.push({ top: randomTop, left: randomLeft, opacity });
    }
    setContainers(initialContainers);
  };

  const handleClick = (index: number) => {
    if (!gameInProgress && index === 0) {
      initializeGame();
      setDiscoveredCount(1);
    }

    if (gameInProgress && containers[index]) {
      const updatedContainers = containers.filter((_, i) => i !== index);
      setContainers(updatedContainers);
      setDiscoveredCount(discoveredCount + 1);
    }
  };

  const handleReset = () => {
    setDiscoveredCount(1);
    setTimeLeft(64);
    setGameInProgress(false);
    setLevel(1);
    initializeGame();
  };

  const close = () => {
    setDiscoveredCount(0);
    setTimeLeft(128);
    setGameInProgress(false);
    setLevel(1);
  };

  const nextLevel = () => {
    setDiscoveredCount(1);
    setTimeLeft(128);
    setGameInProgress(false);
    setLevel(level + 1);
    initializeGame();
  };

  return (
    <>
      {discoveredCount === 64 || timeLeft === 0 ? (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full pacifico">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={close}>
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
              {discoveredCount === 64 ? (
                <>
                  <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5 pacifico">
                    {/* <svg className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg> */}
                    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" className="text-center text-1xl" rel="noopener noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="24" height="24" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor">
                        <path fillRule="evenodd" d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z" clipRule="evenodd"/>
                      </svg>
                    </a>
                  </div>
                  <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Congratulations!</p>
                  
                  {level < 64 && (
                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">You have completed level {level}. Proceed to the next level!</p>
                  )}
                  <div className="flex justify-center space-x-4 pacifico">
                    {level < 64 ? (
                      <button className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fmf focus:ring-4 focus:outline-none focus:ring-fmf dark:focus:ring-blue-900" onClick={nextLevel}>
                        Next Level
                      </button>
                    ) : (
                      <>
                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white pacifico">You have completed the 64th Game! Click the cup for your prize!</p>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <button className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={close}>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </button>
                  <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white pacifico" >Time's up! Try Again.</p>
                  <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white pacifico">Unfortunately, you have reached an antivo number.</p>
                </>
              )}
              <div className="flex justify-center space-x-4 pacifico" >
                <button className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fmf focus:ring-4 focus:outline-none focus:ring-fmf dark:focus:ring-blue-900" onClick={handleReset}>
                  Reset Game
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {gameInProgress && (
            <>
              <div className="overflow-y-auto overflow-x-hidden fixed absolute top-0 right-0 left-0 text-white text-xl justify-center items-center w-full md:inset-0 md:h-full pacifico" >
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                  <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <button className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={close}>
                      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">{discoveredCount} / 64 found</p>
                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">{timeLeft} seconds left</p>
                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">{level} / 64 levels</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {containers.map((container, index) => (
            <div
              key={index}
              className={`absolute transform origin-center transition-transform duration-300 hover:scale-105`}
              style={{ ...container, transitionDuration: level % 2 === 0 ? '2s' : '0.3s', opacity: container.opacity }}
              onClick={() => handleClick(index)}
            >
              <div className="container pacifico">
                <div className={`text-center text-1xl font-bold ${index === 0 ? 'opacity-0' : ''} transition-opacity duration-300 hover:opacity-100 bg-fmf to-purple-500 text-white rounded-full p-2`}>
                  64
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CoolHiddenContainer;
